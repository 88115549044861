<template>
	<div class="app-container">
		<el-tabs v-model="activeName">
			<el-tab-pane label="根因分析" name="1" v-if="permissionControl('RootCauseAnalysis')">
				<analysis ref="performanceGapAnalysis1" libraryName="公司级指标库" v-if="activeName === '1'" pageName="RootCauseAnalysis" />
			</el-tab-pane>
			<el-tab-pane label="结果统计" name="2" v-if="permissionControl('ResultStatistics')">
				<result ref="performanceGapAnalysis2" libraryName="部门级指标库" v-if="activeName === '2'" pageName="ResultStatistics" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import analysis from './analysis.vue';
import result from './result.vue';
export default {
	name: 'performanceGapAnalysis',
	components: { analysis, result },

	data() {
		return { activeName: '' };
	},
	watch: {
		activeName() {
			console.log(this.activeName, 'activeName');
		}
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('performanceGapAnalysisData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'RootCauseAnalysis' },
			{ name: '2', key: 'ResultStatistics' }
		]);
	},
	beforeRouteLeave(to, from, next) {
		sessionStorage.setItem(
			'performanceGapAnalysisData',
			JSON.stringify({
				activeName: this.activeName
				// listQuery: this.$refs[`performanceGapAnalysis${this.activeName}`].listQuery
			})
		);
		next();
	}
};
</script>

<style lang="scss" scoped></style>
