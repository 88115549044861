<template>
	<div class="app-container">
		<formItem
			style="margin-top: 10px"
			ref="formItem"
			:formItems="formItems"
			@cancel="$router.go(-1)"
			:defaultData="formModel"
			:showFooter="false"
		>
		</formItem>
		<div class="views_tree">
			<empty v-if="!treeData.length" />
			<div v-for="(item, inx) in treeData" :key="item.id" :class="`tree_item ${isFullScreen && fullScreenInx === inx && 'fullscreen'}`">
				<div @click="() => handleTitle(inx)">
					<el-row class="titleRow">
						<el-col :span="2"><i :class="activeNames.includes(inx) ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i></el-col>
						<el-col :span="8" v-for="ite in titleList" :key="ite.key">
							<div class="titleText">
								<span>{{ ite.label }}: </span>
								<span>{{ ite.formatter ? ite.formatter(item[ite.key]) : item[ite.key] || '' }}</span>
							</div>
						</el-col>
						<el-col :span="8" style="text-align: right; padding-right: 10px" v-show="!isDownLoading">
							<el-button
								size="mini"
								type="primary"
								icon="el-icon-edit"
								@click.stop="() => handleEditTarget(item)"
								v-if="permissionControlBtns(pageName, 'Edit')"
							>
								编辑
							</el-button>
							<el-button
								size="mini"
								type="danger"
								icon="el-icon-delete"
								@click.stop="() => handleDelTarget(item)"
								v-if="permissionControlBtns(pageName, 'Delete')"
							>
								删除
							</el-button>
							<el-button size="mini" round @click.stop="() => handleFullScreen(inx)"
								><i :class="!isFullScreen ? 'vxe-button--icon vxe-icon-fullscreen' : 'vxe-button--icon vxe-icon-minimize'"></i
							></el-button>
						</el-col>
					</el-row>
				</div>
				<el-collapse-transition>
					<div class="tree_wrap" v-show="activeNames.includes(inx)" v-if="permissionControlBtns(pageName, 'Edit')">
						<div :id="`getTree${inx}`" class="getTree" :style="`height: ${isFullScreen ? '100vh' : '600px'}`">
							<empty />
						</div>
						<div
							v-if="showMenuTips && (editNodeData.targetId || editNodeData.id) === item.id"
							class="menuTips el-popover el-popper"
							:style="`top:${menuStyle.y || 0}px;left:${menuStyle.x || 0}px`"
						>
							<ul class="drop-menu">
								<li class="menu-item" @click="handleAdd">新增子节点</li>
								<li class="menu-item" @click="handleEdit" v-if="!editNodeData.gap">编辑</li>
								<li class="menu-item" @click="handleAddTag" v-if="!editNodeData.gap">标签管理</li>
								<li class="menu-item" @click="handleAddNote" v-if="!editNodeData.gap">添加备注</li>
								<li class="menu-item" @click="handleDel" v-if="!editNodeData.gap">删除</li>
							</ul>
						</div>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<!-- 添加目标 -->
		<el-dialog
			fullscreen
			:title="dialogTitle"
			top="30vh"
			center
			:visible.sync="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogItems"
				@submit="this.addTargetSubmit"
				@cancel="dialogVisible = false"
				:defaultData="dialogModel"
			>
				<baseTable :columns="columns" :showPagination="false" :dataList="dataList" style="padding: 0 20px 20px 20px" :showHeader="false" />
			</formItem>
		</el-dialog>
		<!-- 新增编辑节点 以及目标编辑 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addNodeVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<formItem
				ref="formItem"
				class="formItem"
				:formItems="dialogNodeItems"
				@submit="this.addNodeSubmit"
				@cancel="addNodeVisible = false"
				:defaultData="dialogFormModel"
			>
			</formItem>
		</el-dialog>
		<!-- 标签 -->
		<el-dialog
			:title="dialogTitle"
			top="30vh"
			width="600px"
			center
			:visible.sync="addTagVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<div>
				<div class="tag-title">全部标签</div>
				<div class="color-item">
					<ul>
						<li
							v-for="color in colorList"
							:key="color"
							:style="`background:${color};opacity:${color === selectColor ? 1 : 0.5};${
								color === selectColor && 'outline:2px solid #99e8d9'
							}`"
							@click="selectColor = color"
						></li>
					</ul>
				</div>
				<el-tag
					class="tag-item"
					:key="tag.name + tag.id"
					v-for="tag in tags"
					closable
					:style="`background:${getBackgroundByColor(tag.color)};color:${tag.color}`"
					:disable-transitions="false"
					@close="handleDelTag(tag)"
					@click="handleSelectTag(tag)"
				>
					{{ tag.name }}
				</el-tag>
				<el-input
					class="input-new-tag"
					v-if="inputVisible"
					v-model="inputTagValue"
					ref="saveTagInput"
					size="small"
					@keyup.enter.native="handleInputConfirm"
					@blur="handleInputConfirm"
				>
				</el-input>
				<el-button v-else class="button-new-tag" size="small" @click="showInput" icon="el-icon-plus" title="新增标签"></el-button>
				<div class="tag-title">选中标签</div>
				<el-tag
					class="tag-item"
					:key="'select' + tag.name + tag.id"
					v-for="tag in selectTags"
					closable
					:disable-transitions="false"
					:style="`background:${getBackgroundByColor(tag.color)};color:${tag.color}`"
					@close="selectTags = selectTags.filter(({ name }) => tag.name !== name)"
				>
					{{ tag.name }}
				</el-tag>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addTagVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitAddTag">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 导入按钮的弹窗 -->
		<Upload :visible.sync="dialogImportVisible" :config="uploadConfig" />
	</div>
</template>

<script>
import formItem from '@/views/components/components-form-item/index.vue';
import baseTable from '@/views/components/components-base-table';
import empty from '@/views/components/components-empty/index.vue';
import Upload from '@/components/Upload/index.vue';
import { debounce } from 'loadsh';
import { Loading } from 'element-ui';
import { printDiv } from '@/utils/util';
export default {
	name: 'performanceGapAnalysisList',
	props: { pageName: {} },
	components: { formItem, baseTable, empty, Upload },

	data() {
		return {
			visible: true,
			treeData: [],
			dialogVisible: false,
			dialogModel: {},
			dialogItems: [
				{
					label: '年度',
					key: 'annual',
					type: 'year',
					span: 6,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				},
				{
					label: '产品线',
					key: 'productId',
					type: 'select',
					class: 'pruductClass',
					defaultFirstOption: true,
					async: () =>
						this.$axios
							.get('/v1/common/productConfig/getList', { request: {} })
							.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
					optEdit: true,
					footerRender: true,
					optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
					rules: [{ required: true, message: '请选择', trigger: 'change' }],
					span: 6
				}
			],
			formItems: [
				{
					label: '年度',
					key: 'annual',
					type: 'year',
					span: 6,
					labelWidth: '60px'
				},
				{
					span: 4,
					render: () => (
						<el-button size="small" type="primary" style="margin-top: 5px" icon="el-icon-search" onclick={this.queryData}>
							查询
						</el-button>
					)
					// style: 'text-align: right'
				},
				{
					span: 14,
					render: () => (
						<div>
							{this.permissionControlBtns(this.pageName, 'Add') && (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-plus"
									onclick={() => {
										this.dialogModel = { annual: this.formModel.annual };
										this.dialogVisible = true;
									}}
								>
									添加目标
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Import') && (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-upload"
									onclick={() => {
										this.dialogImportVisible = true;
									}}
								>
									导入
								</el-button>
							)}
							{this.permissionControlBtns(this.pageName, 'Export') && (
								<el-button
									size="small"
									type="primary"
									style="margin-top: 5px"
									icon="el-icon-download"
									onclick={() => {
										this.activeNames = this.treeData.map((_, inx) => inx);
										this.isDownLoading = true;

										// 打印 下载
										this.$nextTick(() => {
											this.resize();
											setTimeout(() => {
												printDiv('views_tree', `业绩差距分析${this.formModel.annual || ''}`, true, false, () => {
													this.isDownLoading = false;
													this.activeNames = [0];
												});
											}, 0);
										});
									}}
								>
									导出
								</el-button>
							)}
						</div>
					),
					style: 'text-align: right'
				}
			],
			formModel: { annual: new Date().getFullYear().toString() },
			columns: [
				{
					// width: 460,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center;width:100%">
							<div style="width: 100px">差距类型</div>
							<el-select
								size="mini"
								placeholder="请选择"
								value={this.dataList[inx].gapType}
								style="width:100%"
								oninput={(e) => {
									this.$set(this.dataList, inx, { ...this.dataList[inx], gapType: e });
								}}
							>
								{this.getOptsById('performanceGapType').map(({ dictId, dictName }) => (
									<el-option label={dictName} value={dictId}></el-option>
								))}
							</el-select>
						</div>
					)
				},
				{
					// width: 460,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center;width:100%">
							<div style="width: 100px">目标</div>
							<el-input
								size="mini"
								type="textarea"
								autosize={true}
								value={this.dataList[inx].target}
								placeholder="请输入"
								oninput={(e) => {
									this.$set(this.dataList, inx, { ...this.dataList[inx], target: e });
								}}
							/>
						</div>
					)
				},
				{
					// width: 460,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center;width:100%">
							<div style="width: 100px">实际达成</div>
							<el-input
								size="mini"
								type="textarea"
								autosize={true}
								value={this.dataList[inx].actual}
								placeholder="请输入"
								oninput={(e) => {
									this.$set(this.dataList, inx, { ...this.dataList[inx], actual: e });
								}}
							/>
						</div>
					)
				},
				{
					// width: 460,
					render: (row, inx) => (
						<div style="display:inline-flex;align-items:center;width:100%">
							<div style="width: 100px">差距</div>
							<el-input
								size="mini"
								type="textarea"
								autosize={true}
								value={this.dataList[inx].gap}
								placeholder="请输入"
								oninput={(e) => {
									this.$set(this.dataList, inx, { ...this.dataList[inx], gap: e });
								}}
							/>
						</div>
					)
				},
				{
					width: 160,
					render: (row, inx) => (
						<div style="text-align:center">
							<el-button type="text" style="margin-top: 5px" icon="el-icon-plus" onclick={() => this.addRow(row, inx)}></el-button>
							<el-button
								type="text"
								style="margin-top: 5px;color:rgb(245, 108, 108)"
								icon="el-icon-delete"
								onclick={() => this.delRow(row, inx)}
							></el-button>
						</div>
					),
					style: 'text-align: right'
				}
			],
			dataList: [{}],
			titleList: [
				{ label: '产品线', key: 'productName' },
				{
					label: '差距类型',
					key: 'gapType',
					formatter: (id) => this.getOptsById('performanceGapType')?.find(({ dictId }) => id === dictId)?.dictName
				},
				{ label: '目标', key: 'target' },
				{ label: '实际达成', key: 'actual' }
			],
			showMenuTips: false,
			menuStyle: {},
			addNodeVisible: false,
			addTagVisible: false,
			dialogFormModel: {},
			dialogTitle: '新增',
			dialogType: '',
			treeChartList: [],
			debounceResize: debounce(this.resize, 300),
			activeNames: [0],
			isDownLoading: false,
			dialogImportVisible: false,
			// 导入配置
			uploadConfig: {
				dictId: 'importTargetInfo',
				groupId: 'importTemplate',
				importUrl: '/v1/performance/target/importTargetInfo',
				successMsg: '年度目标差距分析导入成功',
				callBack: () => {
					this.queryData();
					this.dialogImportVisible = false;
				}
			},
			tags: [
				// { name: '根本原因', color: 'rgb(229, 103, 87)' },
				// { name: '主要原因', color: 'rgb(241, 196, 15)' },
				// { name: '次要原因', color: 'rgb(66, 169, 223)' }
			],
			colorList: ['rgb(66, 169, 223)', 'rgb(34, 178, 150)', 'rgb(241, 196, 15)', 'rgb(229, 103, 87)', 'rgb(169, 114, 205)'],
			selectTags: [],
			inputVisible: false,
			inputTagValue: '',
			selectColor: '',
			isFullScreen: false,
			fullScreenInx: ''
		};
	},
	watch: {
		// treeData(newVal, oldVal) {
		// 	if (newVal.length !== oldVal.length && newVal.length > oldVal.length) {
		// 		this.activeNames = [this.treeData.map((_, inx) => inx)];
		// 	}
		// }
	},
	computed: {
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		},
		getOption() {
			return ({ data, id, name }) => ({
				tooltip: {
					trigger: 'item',
					triggerOn: 'mousemove',
					enterable: false, // 鼠标是否可进入提示框浮层中
					formatter: this.customTips, // 修改鼠标悬停显示的内容
					alwaysShowContent: false // 持续显示
				},

				series: [
					{
						type: 'tree',
						id,
						name,
						data,
						top: '10%',
						left: '12%',
						bottom: '22%',
						right: '20%',
						symbol: 'emptyCircle',
						// orient: 'vertical', // 从上到下
						expandAndCollapse: true,
						symbolSize: 7,
						edgeShape: 'polyline',
						edgeForkPosition: '63%',
						initialTreeDepth: 8, // 初始展开的层级
						lineStyle: {
							width: 2
						},
						label: {
							position: 'left',
							verticalAlign: 'middle',
							align: 'left',
							// rotate: 0,
							fontSize: 14,
							padding: [10, 20],
							borderRadius: 4,
							// backgroundColor: '#fff',
							// color: '#000',
							backgroundColor: '#f8f8f8',
							color: '#333',
							lineHeight: 20,
							// borderWidth: 2,
							// borderColor: '#999',
							shadowBlur: 4,
							shadowColor: '#c8c8c8',
							shadowOffsetX: 1,
							shadowOffsetY: 2,
							wordSpacing: 20,
							formatter: (data) => {
								let val = data.name;
								const maxLength = 20;

								if (val?.length && val.length > maxLength) {
									const len = Math.ceil(val.length / maxLength);

									let arr = [];

									arr.length = len;
									arr.fill(undefined);
									arr = arr.map((it, inx) => val.substr(inx * maxLength, maxLength));
									val = arr.join('\n');
								}
								let tags = '';

								if (data?.data?.labelIds?.length) {
									tags = '\n';
									data.data.labelIds.forEach((i) => {
										const obj = this.tags.find(({ id }) => i === id);

										if (obj) {
											const inx = this.colorList.indexOf(obj?.color);

											tags += `{${inx}|${obj.label}} `;
										}
									});
								}
								if (data?.data?.gap) {
									return `{content|${val}}`;
								}
								return `${val} ${tags}`;
							},
							rich: {
								content: {
									// backgroundColor: '#9DBBD8',
									color: '#333',
									fontSize: 18,
									fontWeight: 'bold',
									lineHeight: 30
								},
								// png: {
								// 	height: 20,
								// 	align: 'center',
								// 	color: '#060606',
								// 	backgroundColor: {
								// 		image: require('../../../assets/unordered.png')
								// 	}
								// },
								0: {
									backgroundColor: 'rgba(66, 169, 223, 0.2)',
									color: 'rgb(66, 169, 223)',
									padding: 5,
									borderRadius: 4,
									fontSize: 12,
									lineHeight: 30
								},
								1: {
									backgroundColor: 'rgba(34, 178, 150, 0.2)',
									color: 'rgb(34, 178, 150)',
									padding: 5,
									borderRadius: 4,
									fontSize: 12,
									lineHeight: 30
								},
								2: {
									backgroundColor: 'rgba(241, 196, 15, 0.2)',
									color: 'rgb(241, 196, 15)',
									padding: 5,
									borderRadius: 4,
									fontSize: 12,
									lineHeight: 30
								},
								3: {
									backgroundColor: 'rgba(229, 103, 87, 0.2)',
									color: 'rgb(229, 103, 87)',
									padding: 5,
									borderRadius: 4,
									fontSize: 12,
									lineHeight: 30
								},
								4: {
									backgroundColor: 'rgba(169, 114, 205, 0.2)',
									color: 'rgb(169, 114, 205)',
									padding: 5,
									borderRadius: 4,
									fontSize: 12,
									lineHeight: 30
								}
							}
						},
						leaves: {
							label: {
								position: 'right',
								verticalAlign: 'middle',
								align: 'left'
							}
						},
						emphasis: {
							focus: 'descendant'
						},
						roam: true,
						animationDuration: 550,
						animationDurationUpdate: 750
					}
				]
			});
		},
		treeRealData() {
			return this.transfromData(
				this.treeData?.map((data) => ({ ...data, causeAnalysis: data.gap, child: data.child })),
				'causeAnalysis',
				'child'
			);
		},
		dialogNodeItems() {
			let arr = [
				{
					label: '原因分析',
					key: 'causeAnalysis',
					type: 'input',
					span: 24,
					rules: { required: true, message: '请输入', trigger: 'blur' }
				}
			];

			if (this.dialogType === 'editTarget') {
				arr = [
					{
						label: '产品线',
						key: 'productId',
						showKey: 'productName',
						type: 'select',
						class: 'pruductClass',
						defaultFirstOption: true,
						async: () =>
							this.$axios
								.get('/v1/common/productConfig/getList', { request: {} })
								.then((res) => res.map(({ id, name }) => ({ dictId: id, dictName: name }))),
						optEdit: true,
						footerRender: true,
						optProps: { add: '/v1/common/productConfig/save', del: '/v1/common/productConfig/delete', key: 'name' },
						rules: [{ required: true, message: '请选择', trigger: 'change' }],
						span: 24
					},
					{
						label: '目标',
						key: 'target',
						type: 'input',
						span: 24,
						rules: { required: true, message: '请输入', trigger: 'blur' }
					},
					{
						label: '实际达成',
						key: 'actual',
						type: 'input',
						span: 24,
						rules: { required: true, message: '请输入', trigger: 'blur' }
					},
					{
						label: '差距',
						key: 'gap',
						type: 'input',
						span: 24,
						rules: { required: true, message: '请输入', trigger: 'blur' }
					}
				];
			}
			if (this.dialogType === 'addNote') {
				arr = [
					{
						label: '备注',
						key: 'remark',
						type: 'textarea',
						span: 24
						// rules: { required: true, message: '请输入', trigger: 'blur' }
					}
				];
			}
			return arr;
		},
		getBackgroundByColor() {
			return (color) => {
				if (!color) {
					return;
				}
				const arr = color.replace('rgb', 'rgba').split(')');

				arr.splice(1, 1, `${0.2})`);
				return arr.join(',');
			};
		}
	},
	async mounted() {
		document.querySelector('.views_tree').addEventListener('contextmenu', this.handleContext);
		document.querySelector('.views_tree')?.addEventListener('click', this.handleViews);
		await this.getTagList();
		// 查询数据
		this.queryData();
		// 窗口变化刷新 视图
		window.onresize = this.debounceResize;
		document.querySelector('.hamburger-container').addEventListener('click', this.debounceResize);
	},

	methods: {
		// 查询数据视图
		queryData() {
			const loading = Loading.service({ target: '.views_tree' });

			this.$axios
				.post('/v1/performance/target/getTargetView', { request: { ...this.formModel } })
				.then((res) => {
					loading.close();
					if (res.code) {
						return;
					}
					this.treeData = res;
					this.$nextTick(() => {
						this.drawingGraph();
					});
				})
				.catch(() => {
					loading.close();
				});
		},
		// 添加一行
		addRow(row) {
			this.dataList.push({ ...row });
		},
		// 删除一行
		delRow(row, inx) {
			if (this.dataList?.length < 2) {
				return;
			}
			this.dataList.splice(inx, 1);
		},
		// 添加目标提交
		addTargetSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const { annual, productId } = this.dialogModel;
					const loading = Loading.service({ target: '.formItem' });

					this.$axios
						.post('/v1/performance/target/saveOrUpdate', {
							request: {
								targetList: this.dataList.map((val) => ({ ...val, annual, productId }))
							}
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.dialogVisible = false;
							this.$message.success('保存成功');
							this.formModel.annual = this.dialogModel.annual;
							this.dialogModel = {};
							this.$set(this.$data, 'dataList', [{}]);
							this.queryData();
						})
						.catch(() => {
							this.dialogVisible = false;
						});
				}
			});
		},
		// 添加编辑节点提交 编辑目标
		addNodeSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const loading = Loading.service({ target: '.formItem' });

					if (this.dialogType === 'editTarget') {
						const params = { ...this.formModel, ...this.dialogFormModel };

						this.$axios
							.post('/v1/performance/target/saveOrUpdate', {
								request: { targetList: [params] }
							})
							.then((res) => {
								loading.close();
								if (res.code) {
									return;
								}
								this.addNodeVisible = false;
								this.$message.success('保存成功');
								// this.queryData();
								this.treeData = this.treeData.map((val) => {
									if (val.id === params.id) {
										return { ...val, ...params };
									}
									return val;
								});
								this.drawingGraph();
							})
							.catch(() => {
								this.dialogVisible = false;
							});
						return;
					}
					if (this.dialogType === 'addNote') {
						this.$axios
							.post('/v1/performance/target/saveGapAnaly', {
								request: { ...this.editNodeData, ...this.dialogFormModel }
							})
							.then((res) => {
								loading.close();
								if (res.code) {
									return;
								}
								this.addNodeVisible = false;
								this.$message.success('添加成功');
								if (this.editNodeData.id) {
									this.treeData = this.changTreeDataById('edit', this.treeData, this.editNodeData.id, {
										...this.dialogFormModel
									});
									this.drawingGraph();
								}
								this.drawingGraph();
							})
							.catch(() => {
								this.dialogVisible = false;
							});
						return;
					}
					const params = {
						...this.editNodeData,
						...this.dialogFormModel,
						targetId: this.editNodeData?.targetId || this.editNodeData?.id,
						id: this.dialogTitle === '新增' ? '' : this.editNodeData?.id,
						// eslint-disable-next-line no-nested-ternary
						parentId: this.dialogTitle === '编辑' ? this.editNodeData.parentId : this.editNodeData?.gap ? '' : this.editNodeData?.id
					};

					this.$axios
						.post('/v1/performance/target/saveGapAnaly', {
							request: params
						})
						.then((res) => {
							loading.close();
							if (res.code) {
								return;
							}
							this.addNodeVisible = false;
							this.$message.success('保存成功');
							if (params.id) {
								this.treeData = this.changTreeDataById('edit', this.treeData, params.id, { ...this.dialogFormModel });
								this.drawingGraph();
								return;
							}
							this.queryData();
						})
						.catch(() => {
							this.addNodeVisible = false;
						});
				}
			});
		},

		transfromData(list, key, childKey) {
			if (!list?.length) {
				return [];
			}
			const arr = [];

			if (list && list.length) {
				list.forEach((data) => {
					let children = data[childKey];

					if (data[childKey]?.length) {
						children = this.transfromData(children, key, childKey);
					}
					arr.push({ ...data, name: data[key], children });
				});
			}
			return arr;
		},
		// 绘图
		drawingGraph() {
			const { init } = this.$echarts;

			const arr = [];

			this.treeRealData.forEach((it, inx) => {
				const dom = document.getElementById(`getTree${inx}`);
				const getTree = init(dom);

				getTree.setOption(
					this.getOption({
						data: [it],
						id: `getTree${inx}`,
						name: `getTree${inx}`
					})
				);
				// 避免事件重复
				getTree.off('contextmenu');
				// getTree.off('click');
				// 添加 右键事件
				getTree.on('contextmenu', (params) => {
					this.showMenuTips = true;
					this.editNodeData = params.data || {};
				});
				// 添加 点击事件
				// getTree.on('click', (params) => {

				// });
				// 视图重置
				getTree.resize();
				arr.push(getTree);
			});
			this.treeChartList = arr;
		},
		// 处理 list里节点 返回处理后list
		changTreeDataById(type, list, id, obj) {
			if (!list?.length) {
				return [];
			}
			const arr = [];

			if (list && list.length) {
				list.forEach((data) => {
					let { child } = data;

					if (data.child?.length) {
						child = this.changTreeDataById(type, child, id, obj);
					}
					if (type === 'delete') {
						if (id !== data.id) {
							arr.push({ ...data, child });
						}
					}
					if (type === 'edit') {
						if (id === data.id) {
							arr.push({ ...data, ...obj, child });
						} else {
							arr.push({ ...data, child });
						}
					}
				});
			}
			return arr;
		},
		// 删除节点
		handleDel() {
			this.showMenuTips = false;
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/performance/target/deleteGapAnaly', {
						request: { gapAnalys: [this.editNodeData.id] }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							// this.queryData();
							this.treeData = this.changTreeDataById('delete', this.treeData, this.editNodeData.id);
							this.drawingGraph();
						}
					});
			});
		},
		// 新增节点
		handleAdd() {
			// 限制 新增条数
			const { children, gap } = this.editNodeData;

			if (children?.length > 2 && gap) {
				return this.$message.warning('仅支持添加最多不超过三条分析');
			}
			if (children?.length > 0 && !gap) {
				return this.$message.warning('仅支持添加一条分析');
			}
			this.dialogType = '';
			this.dialogTitle = '新增';
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogFormModel = {};
		},
		// 编辑节点
		handleEdit() {
			this.dialogType = 'editNode';
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogTitle = '编辑';
			this.dialogFormModel = { causeAnalysis: this.editNodeData?.gap || this.editNodeData?.causeAnalysis };
		},
		// 添加标签
		handleAddTag() {
			this.dialogType = 'addTag';
			this.showMenuTips = false;
			this.addTagVisible = true;
			this.dialogTitle = '标签管理';
			if (this.editNodeData?.labelIds) {
				this.selectTags = this.tags.filter(({ id }) => this.editNodeData.labelIds.includes(id));
				return;
			}
			this.selectTags = [];
			// // 获取所有标签
			// this.getTagList();
		},
		// 获取所有标签
		getTagList() {
			this.$axios.post('/v1/common/lable/getList', { request: { model: '差距分析' } }).then((res) => {
				if (res?.list?.length) {
					this.tags = res.list.map((val) => ({ ...val, name: val.label }));
				}
			});
		},
		// 标签改动提交
		submitAddTag() {
			// if (this.selectTags?.length) {
			this.$axios
				.post('/v1/performance/target/addLabel', {
					request: { nodeId: this.editNodeData.id, labelIds: this.selectTags.map(({ id }) => id) }
				})
				.then((res) => {
					if (res?.code) {
						return;
					}
					if (this.editNodeData.id) {
						this.treeData = this.changTreeDataById('edit', this.treeData, this.editNodeData.id, {
							labelIds: this.selectTags.map(({ id }) => id)
						});
						this.drawingGraph();
					}
					this.$message.success('修改成功');
					this.selectColor = '';
					this.addTagVisible = false;
					this.selectTags = [];
					// this.queryData();
				});
			// } else {
			// 	this.$message.warning('请选择标签');
			// }
		},
		// 添加备注
		handleAddNote() {
			this.dialogType = 'addNote';
			this.showMenuTips = false;
			this.addNodeVisible = true;
			this.dialogTitle = '添加备注';
			console.log(this.editNodeData, 'this.editNodeData');
			this.dialogFormModel = { remark: this.editNodeData.remark };
		},
		// 编辑目标
		handleEditTarget(data) {
			this.dialogType = 'editTarget';
			this.dialogFormModel = { ...data };
			this.dialogTitle = '编辑';
			this.addNodeVisible = true;
		},
		// 删除目标
		handleDelTarget(data) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$axios
					.post('/v1/performance/target/delete', {
						request: { targetIds: [data.id] }
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.isFullScreen = false;
							// this.queryData();
							this.treeData = this.treeData.filter(({ id }) => id !== data.id);
							this.$nextTick(() => {
								this.resize();
							});
						}
					});
			});
		},
		customTips(data) {
			return data.data?.remark && `${data.data?.remark?.replaceAll('\n', '<br/>')}`;
		},
		// 视图刷新
		resize() {
			console.log('click to resize ');
			this.treeChartList.forEach((el) => {
				el.resize();
			});
		},
		// 视图点击
		handleViews(e) {
			console.log('click cav');
			// e.stopPropagation();
			// e.preventDefault();
			if (['CANVAS'].includes(e.target.tagName)) {
				this.showMenuTips = false;
				this.editNodeData = {};
			}
		},
		// 视图右键点击
		handleContext(e) {
			e.stopPropagation();
			e.preventDefault();
			this.menuStyle = { x: e.offsetX, y: e.offsetY };
		},
		// 隐藏展示
		handleTitle(i) {
			if (this.isFullScreen) {
				return;
			}
			if (this.activeNames.includes(i)) {
				this.activeNames = this.activeNames.filter((val) => val !== i);
			} else {
				this.activeNames.push(i);
				this.$nextTick(() => {
					this.resize();
				});
			}
		},
		// 删除标签
		handleDelTag(tag) {
			console.log(this.editNodeData, 'editNodeData');
			this.$confirm('是否确定删除该标签?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				console.log(tag, 'tagggg');

				this.$axios
					.post('/v1/common/lable/delete', {
						request: { ids: [tag.id] },
						sign: ''
					})
					.then((res) => {
						if (res && res.code === 0) {
							this.$message.success('删除成功!');
							this.tags = this.tags.filter(({ id }) => id !== tag.id);
						}
					});
			});
		},
		// 选择tag
		handleSelectTag(tag) {
			if (!this.selectTags.filter(({ name }) => name === tag.name)?.length) {
				this.selectTags.push(tag);
			}
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		// 增加标签
		handleInputConfirm() {
			const { inputTagValue, selectColor } = this;

			if (inputTagValue) {
				if (!selectColor) {
					return this.$message.warning('请为标签选取颜色');
				}
				this.$axios.post('/v1/common/lable/add', { request: { model: '差距分析', label: inputTagValue, color: selectColor } }).then((res) => {
					this.inputVisible = false;
					if (res?.code) {
						return;
					}
					this.inputTagValue = '';
					// this.selectColor = '';
					this.$message.success('新增成功');
					this.tags.push({ label: inputTagValue, name: inputTagValue, color: selectColor, id: res.id });
				});
			} else {
				this.inputVisible = false;
			}
		},
		handleFullScreen(i) {
			this.isFullScreen = !this.isFullScreen;
			this.fullScreenInx = i;
			this.activeNames = [i];
			this.$nextTick(() => {
				this.resize();
			});
		}
	},
	beforeDestroy() {
		document.querySelector('.views_tree')?.removeEventListener('contextmenu', this.handleContext);
		document.querySelector('.views_tree')?.removeEventListener('click', this.handleViews);
		document.querySelector('.hamburger-container')?.removeEventListener('click', this.debounceResize);
	}
};
</script>

<style lang="scss" scoped>
.app-container {
	// padding: 30px 20px !impo rtant;
	.getTree {
		height: 600px;
		width: 100%;
		// margin: 20px 0;
	}
	.titleRow {
		cursor: pointer;
		// height: 40px;
		// line-height: 40px;
		// border-bottom: 1px solid #dcdcdc;
		padding: 10px;
		display: inline-flex;
		width: 100%;
		align-items: center;
		.titleText {
			font-size: 14px;
			color: rgba(0, 0, 0, 0.4);
		}
		.is-round {
			padding: 7px;
		}
	}

	.views_tree {
		background: #fff;

		.tree_wrap {
			position: relative;
			overflow: hidden;
			border-top: 1.8px solid #dcdcdc;
			.menuTips {
				position: absolute;
			}
		}
		.tree_item {
			// box-shadow: 0px 0px 2px #999;
			// margin: 2px;
			margin-bottom: 20px;
			border: 1.8px solid #dcdcdc;
		}
		.fullscreen {
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			z-index: 1001;
			background: #fff;
		}
	}
	.el-tag {
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}
	.tag-title {
		height: 30px;
		line-height: 30px;
	}
	.color-item {
		height: 30px;
		ul {
			display: inline-flex;
			width: 100%;
			height: 100%;
			li {
				margin-right: 20px;
				margin-bottom: 10px;
				width: 20px;
				border-radius: 50%;
				cursor: pointer;
				opacity: 0.6;
			}
		}
	}
	.tag-item {
		cursor: pointer;
		border: none;
	}
	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
		margin-right: 10px;
		margin-bottom: 10px;
	}
}
</style>
