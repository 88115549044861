<!-- 结果统计 -->
<template>
	<div class="container">
		<baseTable ref="baseTable" @request="getTableData" :queryItem="queryItem" :columns="tableCols" showOrder :custom="{ local: true }" />
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
export default {
	name: 'organizationalFunctions',
	components: {
		baseTable
	},

	props: {},
	computed: {
		getOptsById() {
			return (id) => this.$store.state.app?.dict?.filter((item) => item.groupId === id) || [];
		}
	},
	data() {
		return {
			tableCols: [
				{ label: '产品线', key: 'productName' },
				{
					label: '年度',
					key: 'annual'
				},
				{
					label: '类型',
					key: 'gapType',
					formatter: (data) => this.getOptsById('performanceGapType')?.find(({ dictId }) => data === dictId)?.dictName
				},
				{ label: '目标', key: 'target' },
				{ label: '实际达成', key: 'actual' },
				{ label: '差距', key: 'gap' },
				{
					label: '根本原因',
					key: 'causeAnalysis',
					showOverflow: false,
					render: (row) => {
						const arr = row.causeAnalysis?.split('##') || [];

						return (
							<div>
								<ul>
									{arr.map((str) => (
										<li
											style={{
												backgroundColor: 'rgba(229, 103, 87, 0.08)',
												color: 'rgb(229, 103, 87)',
												margin: '4px',
												padding: '4px'
											}}
										>
											{str}
										</li>
									))}
								</ul>
							</div>
						);
					}
				}
			],
			queryItem: [
				{
					label: '年度',
					key: 'annual',
					type: 'year',
					span: 5
				},
				{
					label: '类型',
					key: 'gapType',
					type: 'select',
					groupId: 'performanceGapType',
					span: 5
				}
			]
		};
	},
	created() {},
	mounted() {},
	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/performance/target/resultsStatistical', params));
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	// padding: 20px;
	.addFunctions {
		display: inline-flex;
		align-items: center;
		width: 100%;
		color: #409eff;
		cursor: pointer;
		margin: 20px 0 30px 0;
		justify-content: center;
	}
}
</style>
<style lang="scss"></style>
